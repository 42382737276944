import { createStore } from 'vuex'
import auth from './modules/auth'
import coreui from './modules/coreui'
import gender from './modules/gender'
import product from './modules/product'
import groupAttribute from './modules/groupAttribute'
import attribute from './modules/attribute'
import productImages from './modules/productImages'
import category from './modules/category'
import subcategory from './modules/subcategory'
import orderReport from './modules/orderReport'
import order from './modules/order'
import collection from './modules/collection'

export default createStore({
  modules: {
    coreui,
    auth,
    gender,
    product,
    groupAttribute,
    attribute,
    productImages,
    category,
    subcategory,
    orderReport,
    order,
    collection,
  },
})
