import { getField, updateField } from 'vuex-map-fields'
import axios from '@/axios'
import routes from '@/config/routes'

const { productTopKeywords } = routes

const state = () => {
  return {
    items: [],
    includes: [],
    pagination: {
      current: 1,
      total: 0,
      next: null,
      previous: null,
    },
    search: null,
    collection: {
      id: null,
      title: null,
      title_zh: null,
      title_km: null,
      icon: false,
      keyword: null,
    },
  }
}

const getters = {
  getField,
}

const mutations = {
  updateField,
  handleResetForm(state) {
    state.collection.id = null
    state.collection.title = null
    state.collection.title_zh = null
    state.collection.title_km = null
    state.collection.icon = null
    state.collection.keyword = null
  },
}

const actions = {
  listCollection(_, payload) {
    let url = productTopKeywords.list
    if (payload && Object.keys(payload).length != 0) {
      url = url + '?' + new URLSearchParams(payload).toString()
    }
    return axios.get(url, payload)
  },
  storeCollection(_, params) {
    return axios.post(`${productTopKeywords.store}`, params)
  },
  updateCollection(_, { id, params }) {
    return axios.post(`${productTopKeywords.update}/${id}`, params)
  },
  showCollection(_, { id }) {
    return axios.get(`${productTopKeywords.show}/${id}`)
  },
  deleteCollection(_, { id }) {
    return axios.delete(`${productTopKeywords.delete}/${id}`)
  },
  searchCollections(_, payload) {
    let url = productTopKeywords.searchTopKeywords
    if (payload && Object.keys(payload).length != 0) {
      url = url + '?' + new URLSearchParams(payload).toString()
    }
    return axios.get(url, payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
