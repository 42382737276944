import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import axios from './axios'
import axiosPlugin from './plugins/axiosPlugin'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

import Loading from 'vue3-loading-screen'
import VueToastNotification from 'vue-toast-notification'
import helpers from './plugins/helpers'
import Vue3Lottie from 'vue3-lottie'
import VueViewer from 'v-viewer'
import cookies from './cookies'
import VueMultiselect from 'vue-multiselect'
import VueSweetalert2 from 'vue-sweetalert2'

// styles
import 'vue3-lottie/dist/style.css'
import 'viewerjs/dist/viewer.css'
import 'vue-multiselect/dist/vue-multiselect.css'

// global components
import XCard from '@/components/XCard.vue'
import XWelcome from '@/components/XWelcome.vue'
import XLocaleField from '@/components/Utils/XLocaleField.vue'
import XPagination from '@/components/XPagination.vue'
import XFileUpload from '@/components/XFileUpload.vue'
import XMultiSelect from '@/components/XMultiSelect.vue'
import XVisibility from '@/components/Utils/XVisibility.vue'
import XFeedback from '@/components/Utils/XFeedback.vue'
import XMediaLibrary from '@/components/Utils/XMediaLibrary.vue'
import XCurrencyGroupText from '@/components/Utils/XCurrencyGroupText.vue'
import AppUnderDevelopment from '@/components/AppUnderDevelopment.vue'
import XDeliveryAddress from '@/components/XDeliveryAddress.vue'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(i18n)
app.use(axiosPlugin, { axios })
app.use(helpers)
app.use(Vue3Lottie)
app.use(cookies)
app.use(VueViewer)
app.use(VueSweetalert2)

app.use(VueToastNotification, {
  position: 'top-right',
})

app.use(Loading, {
  bg: '#979797c7',
  slot: `
    <div class="ysl-loading">
      <svg class="spinner" viewBox="0 0 100 100" width="45" height="45">
          <circle cx="50" cy="50" r="42" transform="rotate(-90,50,50)" />
      </svg>
    </div>
  `,
})

// library components
app.component('VueMultiselect', VueMultiselect)

app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('XCard', XCard)
app.component('XWelcome', XWelcome)
app.component('XFileUpload', XFileUpload)
app.component('XPagination', XPagination)
app.component('XMultiSelect', XMultiSelect)
app.component('XVisibility', XVisibility)
app.component('XLocaleField', XLocaleField)
app.component('XFeedback', XFeedback)
app.component('XMediaLibrary', XMediaLibrary)
app.component('XCurrencyGroupText', XCurrencyGroupText)
app.component('AppUnderDevelopment', AppUnderDevelopment)
app.component('XDeliveryAddress', XDeliveryAddress)

app.config.globalProperties.$axios = axios

app.mount('#app')
