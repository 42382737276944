const routes = {
  auth: {
    login: '/v1.0/seller/auth/login',
    getUserData: '/v1.0/seller/auth/user',
    updateSellerProfile: '/v1.0/seller/auth/update-profile',
    uploadSellerProfile: '/v1.0/seller/auth/upload-profile',
  },
  product: {
    category: '/v1.0/seller/shop-categories',
    list: '/v1.0/seller/products',
    show: '/v1.0/seller/products',
    store: '/v1.0/seller/products',
    update: '/v1.0/seller/products',
    delete: '/v1.0/seller/products',
    groupAttributes: '/v1.0/seller/products/group-attributes',
    deleteImages: '/v1.0/seller/products/delete-images',
    getImages: '/v1.0/seller/products/images',
    deleteVideos: '/v1.0/seller/products/delete-videos',
    updateProductAttribute: '/v1.0/seller/products/attribute/update',
    createProductAttribute: '/v1.0/seller/products/attribute/create',
    deleteProductAttribute: '/v1.0/seller/products/attribute/delete',
    downloadProductTemplate: '/v1.0/seller/products/download-products-template',
    uploadFile: '/v1.0/seller/products/upload-products-file',
  },
  productImage: {
    list: '/v1.0/seller/products/images',
    upload: '/v1.0/seller/products/images/upload',
    delete: '/v1.0/seller/products/images/destroy',
    deleteImages: '/v1.0/seller/products/images/delete-images',
    deleteVideos: '/v1.0/seller/products/delete-videos',
  },
  groupAttribute: {
    list: '/v1.0/seller/group-attributes',
    show: '/v1.0/seller/group-attributes',
    store: '/v1.0/seller/group-attributes',
    update: '/v1.0/seller/group-attributes',
    delete: '/v1.0/seller/group-attributes',
  },
  attribute: {
    list: '/v1.0/seller/attributes',
    show: '/v1.0/seller/attributes',
    store: '/v1.0/seller/attributes',
    update: '/v1.0/seller/attributes',
    delete: '/v1.0/seller/attributes',
    groupAttributes: '/v1.0/seller/attributes/group-attributes',
  },
  category: {
    list: '/v1.0/seller/shop-categories',
    store: '/v1.0/seller/shop-categories',
    show: '/v1.0/seller/shop-categories',
    update: '/v1.0/seller/shop-categories',
    delete: '/v1.0/seller/shop-categories',
    searchCategories: '/v1.0/seller/shop-categories/search/categories',
    options: '/v1.0/seller/shop-categories/options',
  },
  subcategory: {
    store: '/v1.0/seller/shop-categories/subcategory',
    show: '/v1.0/seller/shop-categories/subcategory',
    update: '/v1.0/seller/shop-categories/subcategory',
    delete: '/v1.0/seller/shop-categories/subcategory',
  },
  productTopKeywords: {
    searchTopKeywords:
      '/v1.0/seller/shop-product-top-keywords/search/top-keywords',
  },
  orderReport: {
    list: '/v1.0/seller/order-report',
  },
  order: {
    list: '/v1.0/seller/orders',
    detail: '/v1.0/seller/orders',
    shipped_house: '/v1.0/seller/orders',
    accept_order: '/v1.0/seller/orders/items/complete',
    cancel_receive_order_items: '/v1.0/seller/orders/items/cancel',
    cancel_purchased_order_items: '/v1.0/seller/orders/items/cancel-purchased',
    request_origin_delivery:
      '/v1.0/seller/orders/items/request-origin-delivery',
  },
}

export default routes
