import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import DefaultLayout from '@/layouts/DefaultLayout'
import MainLayout from '@/layouts/MainLayout'
import { $cookies } from '@/cookies'

const routes = [
  {
    path: '/',
    name: 'home',
    component: DefaultLayout,
    redirect: '/orders',
    children: [
      {
        path: '/account',
        name: 'account',
        component: () => import('@/views/account/Index.vue'),
      },
      {
        path: '/orders',
        name: 'order',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'orders',
            component: () => import('@/views/orders/Index.vue'),
          },
          {
            path: ':id/detail',
            name: 'order_detail',
            component: () => import('@/views/orders/Detail.vue'),
          },
          {
            path: ':id/shipped-house',
            name: 'order_shipped_house',
            component: () => import('@/views/orders/ShippedHouse.vue'),
          },
        ],
      },
      {
        path: '/catalog',
        name: 'catalog',
        component: () => import('@/views/catalog/Index.vue'),
        redirect: '/catalog/products',
        children: [
          {
            path: '/catalog/products',
            name: 'products',
            component: () => import('@/views/catalog/products/Index.vue'),
            children: [
              {
                path: '',
                name: 'list_products',
                component: () => import('@/views/catalog/products/List.vue'),
              },
              {
                path: 'create',
                name: 'create_products',
                component: () => import('@/views/catalog/products/Create.vue'),
              },
              {
                path: ':id',
                name: 'show_products',
                component: () => import('@/views/catalog/products/Show.vue'),
              },
              {
                path: ':id/edit',
                name: 'edit_products',
                component: () => import('@/views/catalog/products/Edit.vue'),
              },
              {
                path: 'import',
                name: 'import_products',
                component: () => import('@/views/catalog/products/Import.vue'),
              },
            ],
          },
          {
            path: '/catalog/attributes',
            name: 'attributes',
            component: () => import('@/views/catalog/attributes/Index.vue'),
          },
          {
            path: '/catalog/categories',
            name: 'categories',
            component: () => import('@/views/catalog/categories/Index.vue'),
          },
        ],
      },
      {
        path: '/system',
        name: 'system',
        component: () => import('@/views/system/Index.vue'),
        redirect: '/system/settings',
        children: [
          {
            path: '/system/settings',
            name: 'settings',
            component: () => import('@/views/system/settings/Index.vue'),
          },
        ],
      },
      {
        path: '/reports',
        name: 'reports',
        component: () => import('@/views/reports/Index.vue'),
        redirect: '/reports/refund',
        children: [
          {
            path: '/reports/order',
            name: 'orders_and_earnings_report',
            component: () => import('@/views/reports/order/Index.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not_found',
    component: () => import('@/views/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  const goToLogin = () => {
    $cookies.remove('access_token')
    next({ name: 'login' })
  }

  const { user } = store.state.auth
  const accessToken = $cookies.get('access_token')

  if (!user && accessToken) {
    await store
      .dispatch('auth/getUserData')
      .then(async ({ data }) => {
        if (data.data) {
          const { access_token, user } = data.data
          const { shop } = data.includes

          await store.dispatch('auth/setUserData', { user, shop, access_token })
          if (to.name === 'login') next({ name: 'orders' })
          else next()
        } else {
          goToLogin()
        }
      })
      .catch(() => {
        goToLogin()
      })
  } else {
    const { isAuthenticated } = store.state.auth
    if (!isAuthenticated && to.name != 'login') next({ name: 'login' })
    else next()
  }
})

export default router
